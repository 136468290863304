<template>
  <div></div>
</template>

<script setup>
import { onMounted } from 'vue';
import { useStore } from 'vuex';
import { useRouter } from 'vue-router';
import loginApi from '@/api/login.js';
import { message } from 'ant-design-vue';

const router = useRouter();
const store = useStore();

onMounted(() => {
  let temp = window.location.href.split('?')[1];
  let prams = new URLSearchParams('?' + temp);
  let code = prams.get('code');

  loginApi
    .bindWeChatIdentity('', {
      code: code,
      userId: store.state.account.user.id,
    })
    .then(res => {
      if (res) {
        message.success('绑定成功');
      }
      router.replace('/setup');
    });
});
</script>

<style lang="less" scoped></style>
